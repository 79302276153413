import { FormattedMessage } from "react-intl";
const Error = (props) => {
  return (
    <div className="loadingcontainer">
      <p className="loadingtext">
        <FormattedMessage id="errorfetchmsg" />
      </p>
    </div>
  );
};

export default Error;
