import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const CuisineList = (props) => {
  const data = props.data;
  const menudata = props.menudata;
  const [menuid, setMenuID] = useState(null);
  const [menuTitle, setMenuTitle] = useState(null);

  // on explore menu button click.
  const exploreMenu = (selectedIndex, e) => {
    setMenuID(menudata[selectedIndex].id);
    setMenuTitle(menudata[selectedIndex].title);
  };

  return (
    <Row
      className={`bottomBuffer cuisinelist-${props.currentLocale}`}
      dir={props.textDirection}
    >
      <Col xs={12} md={1}></Col>
      <Col xs={12} md={4}>
        <ImageGallery
          items={menudata}
          showNav={false}
          thumbnailPosition="bottom"
          onSlide={exploreMenu}
          useBrowserFullscreen={false}
          showPlayButton={false}
          showFullscreenButton={false}
          autoPlay={true}
          isRTL={props.textDirection === "ltr" ? false : true}
        />
      </Col>
      <Col xs={12} md={6}>
        <h1 className="h1_white">
          {data.title} <br /> {data.subtitle}
        </h1>
        {data.desc &&
          <p
            className="p_white p_cuisine"
            dangerouslySetInnerHTML={{ __html: data.desc }}
          />
        }
        {menuTitle && (
          <button
            className="button_pink rounded-lg"
            variant="secondary"
            onClick={() => props.navigateToMenu(menuid)}
          >
            <FormattedMessage id="discovermenu" /> {menuTitle}
          </button>
        )}
      </Col>
      <Col xs={12} md={1}></Col>
    </Row>
  );
};
export default CuisineList;
