import React, { Fragment } from "react";
import { Col, Row, Image } from "react-bootstrap";
import facebookicon from "../../images/facebookIcon.png";
import Instagramicon from "../../images/instagramicon.png";
import { FormattedMessage } from "react-intl";
const ContactFooter = (props) => {
  return (
    <Fragment>
      <div className="container-fluid">
        <Row>
          <Col xs={12} md={5}></Col>
          <Col xs={12} md={2}>
            <div className="socialshareicon addmarginfrombottom">
              <a
                href="https://www.facebook.com/cafe999qatar/"
                className="socialsharelink"
                target="_blank"
              >
                <Image className="socialshareimg" src={facebookicon} />
              </a>
              <a
                href="https://www.instagram.com/cafe999qatar/?hl=en"
                className="socialsharelink"
                target="_blank"
              >
                <Image className="socialshareimg" src={Instagramicon} />
              </a>
            </div>
          </Col>
          <Col xs={12} md={5}></Col>
        </Row>
        <Row>
          <Col xs={12} md={4}></Col>
          <Col xs={12} md={4}>
            <div className="copyrightext">
              <p>
                <FormattedMessage id="copyrighttext" />
              </p>
            </div>
          </Col>
          <Col xs={12} md={4}></Col>
        </Row>
      </div>
      <div className="contactfooterimg"></div>
    </Fragment>
  );
};

export default ContactFooter;
