import React, { Fragment } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
const Map = () => {
  return (
    <Fragment>
      <Row>
        <Col xs={0} md={1}></Col>
        <Col xs={12} md={12} lg={4}>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3607.0485425392944!2d51.5048593!3d25.3025731!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3e45db2ee689f0e9%3A0x8fe5429f44d30755!2zQ2Fmw6kgIzk5OQ!5e0!3m2!1sen!2sqa!4v1670477503379!5m2!1sen!2sqa"
            width="100%"
            height="100%"
            allowFullScreen={true}
            loading="lazy"
            title="Cafe 999"
            className="cafegooglemap"
          ></iframe>
        </Col>
      </Row>
      <Row className="rowone-top-buffer">
        <Col xs={1} md={1}></Col>
        <Col className="p_white_contact">
          <span className="telephonetitle">
            <FormattedMessage id="telephonelabel" />
          </span>
          <span dir="ltr" className="telephonevalue">
            <FormattedMessage id="telephonevalue" />
          </span>
        </Col>
      </Row>
      <Row>
        <Col xs={1} md={1}></Col>
        <Col className="p_white_contact">
          <span className="telephonetitle">
            <FormattedMessage id="whatsapplabel" />
          </span>
          <span dir="ltr" className="telephonevalue">
            <FormattedMessage id="whatsappvalue" />
          </span>
        </Col>
      </Row>
      <Row className="bottomBuffer">
        <Col xs={1} md={1}></Col>
        <Col className="p_white_contact">
          <span className="telephonetitle">
            <FormattedMessage id="emaillabel" />
          </span>
          <span dir="ltr" className="telephonevalue">
            <FormattedMessage id="emalvalue" />
          </span>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Map;
