import React from "react";
const Wrapper = (props) => {
  const backgroundAttach = props.bgAttachment ? props.bgAttachment : "initial"; 
  return (
    <div
      className={props.clsname}
      style={{
        backgroundImage: `url(${props.bgimage})`,
        backgroundSize: `${props.bgsize}`,
        backgroundAttachment: `${backgroundAttach}`,
        backgroundPosition: `${props.bgPosition}`,
      }}
      id={props.id}
      dir={props.textDirection}
    >
      {props.children}
    </div>
  );
};
export default Wrapper;
