import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import Menulist from "./MenuList";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loading from "../Global/Loading";
import Error from "../Global/Error";
import { env, endpoints } from "../../config/Config";
import fetchData from "../../hooks/fetch-data";
import {
  transformMealsMenu,
  transformMealsMenuItems,
  checkMenuHasSubCat,
} from "../../Helper/Helper";

const Menu = (props) => {
  const [menuMeals, setMenuMeals] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [currentMealsId, setCurrentMealsID] = useState(0);
  const [hasSubcategory, setSubcategory] = useState('yes');
  const [currentMealsTitle, setCurrentMealsTitle] = useState(null);
  const { isLoading, error, sendRequest: fetchDatas } = fetchData();

  useEffect(() => {

    // transform data.
    const transformData = (dataObj) => {
      setMenuMeals(transformMealsMenu(dataObj));
      setMenuItems(transformMealsMenuItems(dataObj));
      setSubcategory(checkMenuHasSubCat(dataObj));
      if (checkMenuHasSubCat(dataObj) === "no") {
        setCurrentMealsTitle(dataObj.data.title);
      } else {
        setCurrentMealsID(transformMealsMenu(dataObj)[0].id);
        setCurrentMealsTitle(transformMealsMenu(dataObj)[0].title);
      }
    };

    // set home page uuid based on current locale.
    const uUid = endpoints[env].UUID;
    let apiPrefix = "/api/";
    if (props.currentLocale === "ar") {
      apiPrefix = "/ar/api/";
    }

    const fetchURL =
      endpoints[env].url +
      apiPrefix +
      "node/meals/" +
      props.id +
      "/?jsonapi_include=1&include=field_meals_menu,field_meals_menu.field_menu_image,field_meals_menu.field_menus,field_meals_menu.field_menus.field_menu_image";
    fetchDatas({ url: fetchURL }, transformData);
  }, [fetchDatas]);

  const handleOnChange = (selectedIndex, e) => {
    if (hasSubcategory === 'yes') {
      setCurrentMealsID(menuMeals[selectedIndex].id);
      setCurrentMealsTitle(menuMeals[selectedIndex].title);
    }
  }

  return (
    <>
      {error && (
        <Error
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
        />
      )}
      {isLoading && (
        <Loading
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
          displayText={false}
        />
      )}
      {!isLoading && (
        <Row
          className={`bottomBuffer menu-${props.currentLocale}`}
          dir={props.textDirection}
        >
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={4}>
            <ImageGallery
              items={menuMeals}
              showNav={false}
              thumbnailPosition="bottom"
              useBrowserFullscreen={false}
              onSlide={handleOnChange}
              showPlayButton={false}
              showFullscreenButton={false}
              autoPlay={true}
              slideInterval={3000}
              thumbnailWidth={150}
              thumbnailHeight={150}
              isRTL={props.textDirection === "ltr" ? false : true}
            />
          </Col>
          <Col xs={12} md={6}>
            <h1 className="h1_white">
              <FormattedMessage id="themenutitle" />
            </h1>
            <Menulist
              mealid={currentMealsId}
              currentMealsTitle={currentMealsTitle}
              data={menuItems}
              navigateToMenu={props.navigateToMenu}
              currentLocale={props.currentLocale}
              hasSubcategory={hasSubcategory}
            />
          </Col>
          <Col xs={12} md={1}></Col>
        </Row>
      )}
    </>
  );
};
export default Menu;