import React, { useState, useEffect } from "react";
import { FormattedMessage } from "react-intl";
import { Col, Row } from "react-bootstrap";
import { env, endpoints } from "../../config/Config";
import fetchData from "../../hooks/fetch-data";
import { transformopeningtime } from "../../Helper/Helper";

const OpeningTime = (props) => {
  const [openingLabel, SetOpeningLabel] = useState('');
  const [openingData, SetOpeningData] = useState([]);
  const { isLoading, error, sendRequest: fetchDatas } = fetchData();

  useEffect(() => {
    const transformOutput = (dataObj) => {
      if (dataObj.data.attributes) {
        SetOpeningLabel(dataObj.data.attributes.field_opening_time_label);
        const openingTransformData = transformopeningtime(
          dataObj.data.attributes.field_open_time_value
        );
        SetOpeningData(openingTransformData);
      }
    };

    // set home page uuid based on current locale.
    const uUid = endpoints[env].UUID;
    let apiPrefix = props.currentLocale === "ar" ? "/ar/api/" : "/api/";

    const fetchURL =
      endpoints[env].url +
      apiPrefix +
      endpoints[env].home +
      "/" +
      uUid +
      "/footersetting";
    fetchDatas({ url: fetchURL }, transformOutput);
  }, []);
  const currentLocale = props.currentLocale;
  return (
    <>
      {!isLoading && (
        <div className={`container-fluid opening-${currentLocale}`}>
          <Row>
            <Col xs={1} md={1}></Col>
            <Col xs={12} md={12}>
              <h1 className="h1_black openingtimeheading" expand="lg">
                {openingLabel}
              </h1>
              <Row className="opninghrs">
                {openingData && openingData.map((item, key) => (
                <Row key={key}>
                  <Col className="openinghrslbl">
                    {item.key}
                  </Col>
                  <Col className="openinghrsval">
                    {item.value}
                  </Col>
                </Row>
                ))}
              </Row>
            </Col>
          </Row>
        </div>
      )}
    </>
  );
};

export default OpeningTime;
