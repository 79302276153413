export const metaDetail = {
  home: {
    title: "Home | Cafe 999",
    description: "",
  },
  concept: {
    title: "Concept | Cafe 999",
    description: "",
  },
  cuisine: {
    title: "Cuisine | Cafe 999",
    description: "",
  },
  news: {
    title: "News | Cafe 999",
    description: "",
  },
  theteam: {
    title: "The Team | Cafe 999",
    description: "",
  },
  contact: {
    title: "Contact US | Cafe 999",
    description: "",
  },
  notfound: {
    title: "404 | Not Found",
    description: "",
  },
};
