export const env = process.env.REACT_APP_APP_ENV || "local";

export const endpoints = {
  dev: {
    url: "https://stagingcmscafee999.azurewebsites.net",
    home: "homepage",
    UUID: "d67942a6-2884-4543-b427-5e8be7d5c10f",
    img_url: "https://stagingcmscafee999.azurewebsites.net",
  },
  prod: {
    url: "https://live999cafeappservice.azurewebsites.net",
    home: "homepage",
    UUID: "d67942a6-2884-4543-b427-5e8be7d5c10f",
    img_url: "https://live999cafeappservice.azurewebsites.net",
  },
  local: {
    url: "http://localhost:8080",
    home: "homepage",
    UUID: "d67942a6-2884-4543-b427-5e8be7d5c10f",
    img_url: "http://localhost:8080",
  },
};

export const mailchimpurl = "https://inq-online.us19.list-manage.com/subscribe/post-json?u=ca4ee85431bdfdfe25c9306c5&amp;id=dda12cf6ca&amp;f_id=004293e4f0";
