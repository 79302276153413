import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { mailchimpurl } from "../../config/Config";
import jsonp from "jsonp";
import { isMobile } from "react-device-detect";

const SubscriberForm = (props) => {
  const currentLocale = props.currentLocale;
  const [subscriberEmail, SetSubscriberEmail] = useState('');
  const [subsMsg, setSubsMsg] = useState(null);
  const [msgColorClass, setColorClass] = useState(null);

  // submit subscriber form
  const onSubcriberFormSubmit = (event) => {
    event.preventDefault();
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!subscriberEmail.match(validRegex)) {
      if (currentLocale == "en") {
        setColorClass("text-danger");
        setSubsMsg("Please enter valid email id");
      } else {
        setColorClass("text-danger");
        setSubsMsg("الرجاء إدخال البريد الإلكتروني الصحيح");
      }
    } else {
      jsonp(
        `${mailchimpurl}&EMAIL=${subscriberEmail}`,
        { param: "c" },
        (_, data) => {
          const { msg, result } = data;
          SetSubscriberEmail("");
          setColorClass("text-success");
          setSubsMsg(msg);
        }
      );
    }
  };

  // capture email
  const emailChangeHandler = (event) => {
    SetSubscriberEmail(event.target.value);
  };
  return (
    <div className={`container-fluid subsform-${currentLocale}`}>
      <div className="addmarginfrombottom">
        <Row className="addmarginfrombottom">
          <Col xs={1} md={1}></Col>
          <Col xs={12} md={12}>
            <h1 className="signupheading" expand="lg">
              <FormattedMessage id="signuptext" />
            </h1>
          </Col>
        </Row>
        <Row>
          <Col xs={12} md={2}></Col>
          <Col xs={12} md={8}>
            <input
              name="email"
              className="singupforminput"
              placeholder={
                currentLocale === "en" ? "Email Address" : "بريد إلكتروني"
              }
              value={subscriberEmail}
              onChange={emailChangeHandler}
            />
            {subsMsg && <p className={msgColorClass}>{subsMsg}</p>}
          </Col>
          <Col
            className={`${isMobile ? "subscribebutton" : "subscribebutton-desktop"}`}
            xs={12}
            md={2}
          >
            <button
              type="button"
              className="sfsubmitbutton"
              onClick={onSubcriberFormSubmit}
            >
              <FormattedMessage id="submitlabel" />
            </button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default SubscriberForm;
