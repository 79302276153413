import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";

const VideoWrapper = ({
  videoUrl,
  ind,
  currentSlideIndex,
  setIsPaused,
  title,
  description,
}) => {
  const [isPlaying, SetIsPlaying] = useState(true);
  const isScrolled = (el) => {
   return el.getBoundingClientRect().top.toFixed() <= 0;
  }

  const onScroll = () => {
    const scrollWrapper = document.getElementById("concept");
    if (scrollWrapper) {
      if (isScrolled(scrollWrapper)) {
       SetIsPlaying(false);
      }
    }
  };

  const updateStateOfPauseAndPlaying = (ps, pl) => {
    setIsPaused(ps);
    SetIsPlaying(pl);
  };

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    //console.log("adding scroll event listener");

    // Clean-up
    return () => {
      document.removeEventListener("scroll", onScroll);
    };
  }, []);

  return (
    <div className="vidoeonlyslider">
      {ind === currentSlideIndex && (
        <div className="player-wrapper">
          <div id="overlaybox" className="box overlay">
            <div className="row">
              <div className="col-md-1 col-12"></div>
              <div className="col-md-10 col-12">
                <p className="p_slider_home_overlay" expand="lg">
                  {title}
                </p>
                <p
                  id={`bannervalue-${ind}`}
                  className="p_slider_home_desc_overlay"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </div>
              <div className="col-md-1 col-12"></div>
            </div>
          </div>
          <ReactPlayer
            url={videoUrl}
            playing={isPlaying}
            onStart={() => updateStateOfPauseAndPlaying(true, true)}
            onEnded={() => updateStateOfPauseAndPlaying(false, true)}
            onPlay={() => updateStateOfPauseAndPlaying(true, true)}
            volume={0}
            muted={true}
            width="100%"
            height="100%"
            controls={true}
            className="react-player"
          />
        </div>
      )}
      <div className="container videodesc_mobile">
        <p className="p_slider_home" expand="lg">
          {title}
        </p>
        <p
          id={`bannervalue-${ind}`}
          className="p_slider_home_desc"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </div>
    </div>
  );
};
export default VideoWrapper;
