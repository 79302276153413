import React, { memo } from "react";
import PropTypes from "prop-types";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { metaDetail } from "../../config/Constant";
import Sitelogo from "../../images/cafelogored.png";
const Seo = memo(function Seo(props) {
  const pageTitle = props.title || metaDetail[props.page].title;
  const pageDesc = props.desc || metaDetail[props.page].description;
  return (
    <HelmetProvider>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDesc} />
        <meta
          data-rh="true"
          property="og:image"
          name="og:image"
          content={Sitelogo}
        />
        <meta
          data-rh="true"
          property="og:title"
          name="og:title"
          content={metaDetail[props.page].title}
        />
        <meta
          data-rh="true"
          property="og:description"
          name="og:description"
          content={metaDetail[props.page].description}
        />
        <meta
          data-rh="true"
          name="twitter:title"
          content={metaDetail[props.page].title}
        />
        <meta
          data-rh="true"
          name="twitter:description"
          content={metaDetail[props.page].description}
        />
        <meta
          data-rh="true"
          name="twitter:image:alt"
          content={metaDetail[props.page].title}
        />
        <meta data-rh="true" name="twitter:image:src" content={Sitelogo} />
      </Helmet>
    </HelmetProvider>
  );
});

Seo.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
};
export default Seo;
