import React, { useRef, useState } from "react";
import { FormattedMessage } from "react-intl";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import LogoRed from "../../images/cafelogored.png";
import WorldImg from "../../images/icons/icon-language.svg";
import LogoWhite from "../../images/cafelogowhite.png";
import { Col, Row, Image } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { isMobile } from "react-device-detect";

const Header = (props) => {
  const togglerButtonRef = useRef(null);
  const [actMenu, setActMenu] = useState('home');
  const switchLang = (e) => {
    window.location.href = "/" + e.target.value;
  };

  // hide menu togglebar on click of menu link
  const menuClickHandler = (id) => {
    setActMenu(id);
    props.menuclickeditem(id);

    // scroll to section.
    const timer = setTimeout(() => {
      const body = document.getElementById(id);
      if (body) {
        body.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 100);
    if (isMobile) togglerButtonRef.current.click();
    return () => clearTimeout(timer);
  };

  return (
    <>
      <Container fluid className="header" dir={props.textDirection}>
        <Row className={`header-${props.currentLocale}`}>
          <Col xs={12} md={1}></Col>
          <Col xs={12} md={10}>
            <Navbar
              className="header-container"
              expand="lg"
              fixed="top"
              collapseOnSelect="true"
            >
              <Container fluid className="header">
                <Navbar.Brand
                  href={`/${props.currentLocale}`}
                  className="navbrad-logo"
                >
                  <img
                    className="redlogo"
                    src={props.logo === "red" ? LogoRed : LogoWhite}
                    alt="Logo"
                  />
                </Navbar.Brand>
                <Navbar.Toggle
                  aria-controls="responsive-navbar-nav"
                  ref={togglerButtonRef}
                />
                <Navbar.Collapse id="responsive-navbar-nav">
                  <Nav className="ms-auto my-2 my-lg-0">
                    <NavLink
                      to={`/${props.currentLocale}`}
                      end="true"
                      className={`navlink greyColor ${
                        actMenu === "home"
                          ? "activeMenuClass nav-link"
                          : "nav-link"
                      }`}
                      onClick={() => menuClickHandler("home")}
                    >
                      <FormattedMessage id="home" />
                    </NavLink>
                    <NavLink
                      to={`/${props.currentLocale}#concept`}
                      end="true"
                      className={`navlink greyColor ${
                        actMenu === "concept"
                          ? "activeMenuClass nav-link"
                          : "nav-link"
                      }`}
                      onClick={() => menuClickHandler("concept")}
                    >
                      <FormattedMessage id="concept" />
                    </NavLink>
                    <NavLink
                      to={`/${props.currentLocale}#cuisine`}
                      end="true"
                      className={`navlink greyColor ${
                        actMenu === "cuisine"
                          ? "activeMenuClass nav-link"
                          : "nav-link"
                      }`}
                      onClick={() => menuClickHandler("cuisine")}
                    >
                      <FormattedMessage id="cuisine" />
                    </NavLink>
                    <NavLink
                      to={`/${props.currentLocale}#theteam`}
                      end="true"
                      className={`navlink greyColor ${
                        actMenu === "theteam"
                          ? "activeMenuClass nav-link"
                          : "nav-link"
                      }`}
                      onClick={() => menuClickHandler("theteam")}
                    >
                      <FormattedMessage id="team" />
                    </NavLink>
                    <NavLink
                      to={`/${props.currentLocale}/news`}
                      end="true"
                      className={({ isActive }) =>
                        isActive
                          ? "navlink greyColor activeMenuClass nav-link"
                          : "navlink greyColor nav-link"
                      }
                      onClick={() => menuClickHandler("news")}
                    >
                      <FormattedMessage id="news" />
                    </NavLink>
                    <NavLink
                      to={`/${props.currentLocale}/contact`}
                      end="true"
                      className={({ isActive }) =>
                        isActive
                          ? "navlink greyColor activeMenuClass nav-link"
                          : "navlink greyColor nav-link"
                      }
                      onClick={() => menuClickHandler("contactus")}
                    >
                      <FormattedMessage id="contact_us" />
                    </NavLink>
                    <Nav.Link>
                      <div>
                        <Image src={WorldImg} />
                        <select
                          className="langswitcher"
                          onChange={switchLang}
                          value={props.currentLocale}
                        >
                          <option value="en">English</option>
                          <option value="ar">العربية</option>
                        </select>
                      </div>
                    </Nav.Link>
                  </Nav>
                </Navbar.Collapse>
              </Container>
            </Navbar>
          </Col>
          <Col xs={12} md={1}></Col>
        </Row>
      </Container>
    </>
  );
};

export default Header;
