import React, { useEffect } from "react";
import { FormattedMessage } from "react-intl";
import Map from "./Map";
import bgContact from "../../images/contactbg-05.png";
import bgContactAr from "../../images/fliped-contact.png";
import { Col, Row } from "react-bootstrap";
import Wrapper from "../Global/Wrapper";
import OpeningTime from "./OpeningTime";
import SubscriberForm from "./SubscriberForm";
import ContactFooter from "./ContactFooter";
import Seo from "../Global/Seo";

const Contact = (props) => {
  const bgPosition = props.currentLocale == "en" ? "left" : "right";

  useEffect(() => {
      const timer = setTimeout(() => {
        window.scrollTo(0, 0);
      }, 100);
      return () => clearTimeout(timer);
  }, []);
  
  return (
    <>
      <Seo page="contact" />
      <Wrapper
        clsname={`pagecontainer contact-${props.currentLocale}`}
        bgimage={props.currentLocale === 'ar' ? bgContactAr : bgContact}
        bgsize="cover"
        bgPosition={bgPosition}
        id="contact"
        textDirection={props.textDirection}
      >
        {
          <>
            <div className="container-fluid">
              <div className="top-buffer"></div>
              <Row>
                <Col xs={1} md={1}></Col>
                <Col xs={6} md={4}>
                  <h1 className="h1_white" expand="lg">
                    <FormattedMessage id="contacttitle" />
                  </h1>
                  <p className="contact_desc">
                    <FormattedMessage id="contactdesc" />
                  </p>
                </Col>
              </Row>
              <Map />
            </div>
          </>
        }
      </Wrapper>
      <OpeningTime
        currentLocale={props.currentLocale}
        textDirection={props.textDirection}
      />
      <SubscriberForm
        currentLocale={props.currentLocale}
        textDirection={props.textDirection}
      />
      <ContactFooter
        currentLocale={props.currentLocale}
        textDirection={props.textDirection}
      />
    </>
  );
};

export default Contact;