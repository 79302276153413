import React, { useEffect, useState } from "react";
import Wrapper from "../Global/Wrapper";
import Seo from "../Global/Seo";
import NewsList from "./NewsList";
import Error from "../Global/Error";
import { env, endpoints } from "../../config/Config";
import fetchData from "../../hooks/fetch-data";
import { transformNews, transformNewsBg } from "../../Helper/Helper";

const News = (props) => {
    const [news, SetNews] = useState([]);
    const [newsBgImg, SetNewsBgImg] = useState(null);
    const { isLoading, error, sendRequest: fetchDatas } = fetchData();
    const bgPosition = props.currentLocale == "en" ? "left" : "right";

    useEffect(() => {
      // scroll to top first.
      window.scrollTo(0, 0);

      // transform data.
      const transformData = (dataObj) => {
        SetNews(transformNews(dataObj));
        SetNewsBgImg(transformNewsBg(dataObj));
      };

      // set home page uuid based on current locale.
      const uUid = endpoints[env].UUID;
      let apiPrefix = "/api/";
      if (props.currentLocale === "ar") {
        apiPrefix = "/ar/api/";
      }

      let fetchURL =
        endpoints[env].url +
        apiPrefix +
        endpoints[env].home +
        "/" +
        uUid +
        "/?jsonapi_include=1&include=newsbgimage,news,news.field_image,news.field_tags";

      fetchDatas({ url: fetchURL }, transformData);
    }, [fetchDatas]);

    return (
      <>
        {error && (
          <Error
            textDirection={props.textDirection}
            currentLocale={props.currentLocale}
          />
        )}
        <Wrapper
          clsname={`pagecontainer news-${props.currentLocale}`}
          bgimage={newsBgImg}
          bgsize="cover"
          bgPosition={bgPosition}
          id="news"
          textDirection={props.textDirection}
        >
          <Seo page="news" />
          <div className="top-buffer"></div>
          <div className="container-fluid">
            {!isLoading && (
              <NewsList
                data={news}
                textDirection={props.textDirection}
                currentLocale={props.currentLocale}
              />
            )}
          </div>
        </Wrapper>
      </>
    );
};
export default News;
