import { Col, Row, Image } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useNavigate } from "react-router-dom";

const NewsList = (props) => {
  let navigate = useNavigate();
  const news =  props.data;
  
  // navigat to single news page.
  const navigateToNews = (url) => {
    const newsUrl = "/" + url;
    navigate(newsUrl);
  };

  return (
    <div>
      {news.length > 0 &&
        news.map((item, key) => (
          <div key={key}>
            <Row className="newslist">
              <Col xs={12} md={1}></Col>
              <Col xs={12} md={2}>
                <Image src={item.image} className="newsimg" />
              </Col>
              <Col xs={12} md={8}>
                <Row>
                  <Col>
                    <h5 className="h5_news">{item.title}</h5>
                  </Col>
                </Row>
                <Row>
                  <Col>{item.summary}</Col>
                </Row>
                <Row>
                  <Col className="newslistmore">
                    <button
                      className="button_orange rounded-lg"
                      variant="secondary"
                      onClick={() =>
                        navigateToNews(
                          item.lang + "/news/" + item.id + item.path
                        )
                      }
                    >
                      <FormattedMessage id="morebutton" />
                    </button>
                  </Col>
                </Row>
              </Col>
              <Col xs={12} md={1}></Col>
            </Row>
            <Row>
              <Col xs={12} md={1}></Col>
              <Col xs={12} md={10}>
                <hr></hr>
              </Col>
              <Col xs={12} md={1}></Col>
            </Row>
          </div>
        ))}
      {!news && news.length < 1 && (
        <p className="notfoundpara">
          <FormattedMessage id="nodatafound" />
        </p>
      )}
    </div>
  );
};
export default NewsList;
