import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-slideshow-image/dist/styles.css";
import { Col, Row, Image } from "react-bootstrap";
import MobileMenu from "../../Menu/mobile/MobileMenu";
import Wrapper from "../../Global/Wrapper";

const HomeCuisine = (props) => {
  const [menuClicked, SetMenuClicked] =  useState(false);
  const [clickedMenuId, SetClickedMenuId] =  useState(null);
  const data = props.data;
  const menudata = props.menudata;
  const bgPosition = props.currentLocale == "en" ? "left" : "right";
  const goToMenu = (id) => {
    SetMenuClicked(true);
    SetClickedMenuId(id);
    props.onMobileMenuPage(false);
  };

  const returnFromMenu = () => {
    props.onMobileMenuPage(false);
    SetClickedMenuId(null);
    SetMenuClicked(false);
  };

  return (
    <Wrapper
      clsname="pagecontainer"
      bgimage={data.bgImg}
      bgPosition={bgPosition}
      dir={props.textDirection}
      bgsize="cover"
      bgAttachment="initial"
      id="cuisine"
    >
      {
        <div className="container-fluid">
          <div className="top-buffer"></div>
          {!menuClicked && (
            <div
              className={`mobilecuisinelist mobilecuisinelist-${props.currentLocale}`}
              dir={props.textDirection}
            >
              <Row>
                <Col xs={1} md={1}></Col>
                <Col xs={10} md={10}>
                  <h1 className="h1_white">
                    {data.title} <br /> {data.subtitle}
                  </h1>
                  {data.desc && (
                    <p
                      className="p_white p_cuisine"
                      dangerouslySetInnerHTML={{ __html: data.desc }}
                    />
                  )}
                </Col>
                <Col xs={1} md={1}></Col>
              </Row>
              <Row>
                <Col xs={1} md={1}></Col>
                <Col xs={10} md={10}>
                  {menudata &&
                    menudata.map((item, key) => (
                      <Col
                        onClick={() => goToMenu(item.id)}
                        className={`${
                          (key + 1) % 2 == 0
                            ? "mobilecuisinelistdivspace"
                            : "mobilecuisinelistdiv"
                        }`}
                        xs={6}
                        md={6}
                        key={`key-${key}`}
                      >
                        <Image src={item.original} className="mobilemenuimg" />
                        <span
                          className={`${
                            (key + 1) % 2 == 0
                              ? "mobilecusinetextspace"
                              : "mobilecusinetext"
                          }`}
                        >
                          {item.title}
                        </span>
                      </Col>
                    ))}
                </Col>
                <Col xs={1} md={1}></Col>
              </Row>
            </div>
          )}
          {menuClicked && (
            <MobileMenu
              menuid={clickedMenuId}
              currentLocale={props.currentLocale}
              textDirection={props.textDirection}
              returnFromMenu={returnFromMenu}
            />
          )}
        </div>
      }
    </Wrapper>
  );
};

export default HomeCuisine;
