import React, { useEffect, useState } from "react";
import Wrapper from "../Global/Wrapper";
import Seo from "../Global/Seo";
import { Col, Row, Image } from "react-bootstrap";
import Error from "../Global/Error";
import { env, endpoints } from "../../config/Config";
import fetchData from "../../hooks/fetch-data";
import { transformSingleNews } from "../../Helper/Helper";
import { useLocation } from "react-router-dom";

const NewsDetails = (props) => {
  const location = useLocation();
  const [newsDetail, SetNewsDetail] = useState([]);
  const [pageTitle, SetPageTitle] = useState(null);
  const [pageDesc, SetPageDesc] = useState(null);
  const { isLoading, error, sendRequest: fetchDatas } = fetchData();
  const bgPosition = props.currentLocale == "en" ? "left" : "right";

  useEffect(() => {
    // scroll to top first.
    window.scrollTo(0, 0);

    const newspath = location.pathname.split("/");

    // transform data.
    const transformData = (dataObj) => {
      SetNewsDetail(transformSingleNews(dataObj));
      SetPageTitle(transformSingleNews(dataObj)[0].title);
      SetPageDesc(transformSingleNews(dataObj)[0].summary);
    };

    // set home page uuid based on current locale.
    const uUid = endpoints[env].UUID;
    let apiPrefix = "/api/";
    if (props.currentLocale === "ar") {
      apiPrefix = "/ar/api/";
    }

    const fetchURL =
      endpoints[env].url +
      apiPrefix +
      "node/article/" +
      newspath[3] +
      "?jsonapi_include=1&include=field_image,field_tags";

    fetchDatas({ url: fetchURL }, transformData);
  }, [fetchDatas]);

  return (
    <>
      {error && (
        <Error
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
        />
      )}
      <Wrapper
        clsname={`pagecontainer newsdetail-${props.currentLocale}`}
        bgimage=""
        bgsize="cover"
        bgPosition={bgPosition}
        id="news"
        textDirection={props.textDirection}
      >
        <Seo page="news" title={pageTitle} desc={pageDesc} />
        <div className="top-buffer"></div>
        <div className="container-fluid">
          {!isLoading &&
            newsDetail.map((item, key) => (
              <div key={key}>
                <Row>
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={10}>
                    <h5 className="h1_news">{item.title}</h5>
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
                <Row className="newslist">
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={10}>
                    <Image src={item.image} className="newsimgdetails" />
                    <div dangerouslySetInnerHTML={{ __html: item.body }} />
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
                <Row className="newstaglist">
                  <Col xs={12} md={1}></Col>
                  <Col xs={12} md={10}>
                    {item.tag.map((tags, key) => (
                      <a
                        href="#"
                        key={key}
                        className="button_orange rounded-lg newstag"
                      >
                        {tags}
                      </a>
                    ))}
                  </Col>
                  <Col xs={12} md={1}></Col>
                </Row>
              </div>
            ))}
        </div>
      </Wrapper>
    </>
  );
};
export default NewsDetails;
