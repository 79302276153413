import React, { useState } from "react";
import "react-image-gallery/styles/css/image-gallery.css";
import Wrapper from "../Global/Wrapper";
import Menu from "../Menu/Menu";
import CuisineList from "./CuisineList";

const Cuisine = (props) => {
  let renderComponent;
  const data = props.data;
  const menudata = props.menudata;
  const [selectedMenu, setSelectedMenu] = useState(null);
  const bgPosition = props.currentLocale == "en" ? "left" : "right";

  // navigate to particular menu from cuisine.
  const navigateToMenu = (menuid) => {
    setSelectedMenu(menuid);

    // scroll to top of cuisine block.
    const timer = setTimeout(() => {
      const body = document.getElementById("cuisine");
      if (body) {
        body.scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
      }
    }, 100);
    return () => clearTimeout(timer);
    
  };

  if (selectedMenu) {
    renderComponent = (
      <Menu
        navigateToMenu={navigateToMenu}
        id={selectedMenu}
        textDirection={props.textDirection}
        currentLocale={props.currentLocale}
      />
    );
  } else {
    renderComponent = (
      <CuisineList
        navigateToMenu={navigateToMenu}
        textDirection={props.textDirection}
        currentLocale={props.currentLocale}
        data={data}
        menudata={menudata}
      />
    );
  }
  return (
    <Wrapper
      clsname="pagecontainer"
      bgimage={data.bgImg}
      bgsize="cover"
      bgPosition={bgPosition}
      id="cuisine"
      dir={props.textDirection}
    >
      {
        <>
          <div className="top-buffer"></div>
          <div className="container-fluid">{renderComponent}</div>
        </>
      }
    </Wrapper>
  );
};
export default Cuisine;