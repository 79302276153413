import React, { useState, useRef } from "react";
import Wrapper from "../Global/Wrapper";
import VideoWrapper from "../Global/VideoWrapper";
import "bootstrap/dist/css/bootstrap.min.css";
import { Col, Row } from "react-bootstrap";
import { Slide } from "react-slideshow-image";
import "react-slideshow-image/dist/styles.css";
import { isMobile } from "react-device-detect";

const Main = (props) => {
  const slideRef = useRef(null);
  const [isPaused, setIsPaused] = useState(false);
  const [currentSlideIndex, setSlideIndex] = useState(0);
  const bannerImages = props.dataImg;
  const bannerData = props.data;
  const bgPosition = props.currentLocale == "en" ? "left" : "right";
  const desktopClass = `home-pagecontainer homepage-${props.currentLocale}`;
  const mobileClass = `home-pagecontainer homepage-${props.currentLocale} home-pagecontainer-mobile`;

  const properties = {
    duration: 3000,
    transitionDuration: 500,
  };

  const pauseSlideshow = (input) => {
    setIsPaused(input);
    //slideRef.current.pause();
  };

  return (
    <Wrapper
      clsname={`${isMobile ? mobileClass : desktopClass}`}
      bgimage={bannerImages[currentSlideIndex]}
      bgsize="cover"
      id="home"
      bgPosition={bgPosition}
      textDirection={props.textDirection}
    >
      <div className="container-fluid pe-0">
        <Row>
          <Col xs={12} md={12}></Col>
        </Row>
        <Slide
          ref={slideRef}
          autoplay={!isPaused}
          arrows={true}
          indicators={false}
          onStartChange={function noRefCheck(from, to) {
            setSlideIndex(to);
          }}
          dir={props.textDirection}
          {...properties}
        >
          {bannerData.map((banner, key) => (
            <div
              className={banner.type === "img" ? "top-buffer" : "top-buffer-yt"}
              key={key}
              dir={props.textDirection}
            >
              {banner.type === "img" && (
                <div className="imageonlyslider row">
                  <Col xs={1} md={1}></Col>
                  <Col xs={10} md={9}>
                    <h1 className="h1_home" expand="lg">
                      {banner.title}
                    </h1>
                    <h4
                      id={`bannervalue-${key}`}
                      className="h4_home"
                      dangerouslySetInnerHTML={{ __html: banner.value }}
                    />
                  </Col>
                  <Col xs={1} md={2}></Col>
                </div>
              )}
              {banner.type === "ytvideo" && (
                <VideoWrapper
                  videoUrl={banner.video}
                  ind={key}
                  currentSlideIndex={currentSlideIndex}
                  setIsPaused={pauseSlideshow}
                  title={banner.title}
                  description={banner.value}
                />
              )}
            </div>
          ))}
        </Slide>
      </div>
    </Wrapper>
  );
};

export default Main;
