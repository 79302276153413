import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Wrapper from "../Global/Wrapper";
import { directionButtons } from "../../Helper/Helper";
import { isMobile } from "react-device-detect";

const TheTeam = (props) => {
  const backgroundImage = props.bgImg;
  const data = props.data;
  const imgData = props.imgData;
  const [dataIndex, setIndex] = useState(0);
  const bgPosition = props.currentLocale == "en" ? "left" : "right";
  const desktopClass = `pagecontainer theteam-${props.currentLocale}`;
  const mobileClass = `pagecontainer theteam-${props.currentLocale} pagecontainer-mobile`;


  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);

  };

  return (
    <Wrapper
      clsname={`${isMobile ? mobileClass : desktopClass}`}
      bgimage={backgroundImage}
      bgsize="cover"
      bgPosition={bgPosition}
      id="theteam"
      textDirection={props.textDirection}
    >
      {
        <>
          <div className="top-buffer"></div>
          <div className="container-fluid">
            <Row>
              <Row xs={12} md={4}></Row>
              <Col xs={12} md={4} className="removePadding teamcarousel">
                <Carousel
                  indicators={false}
                  onSelect={handleSelect}
                  autoPlay={true}
                  nextIcon={imgData.length > 1 ? directionButtons("Next") : ""}
                  prevIcon={
                    imgData.length > 1 ? directionButtons("Previous") : ""
                  }
                >
                  {imgData.map((img, key) => (
                    <Carousel.Item key={img.id}>
                      <img
                        className="img_team"
                        src={img.image}
                        alt="cafe_999"
                        width="100%"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={1} md={0}></Col>
                  <Col xs={10} md={12}>
                    {data.map((item, key) => (
                      <div
                        className={`${
                          dataIndex === key
                            ? "showstaffdetails"
                            : "hideinitially"
                        }`}
                        key={key}
                        id={`id-${key}`}
                      >
                        <h1 className="h1_white">
                          {item.name}
                          <br />
                          {item.title}
                        </h1>
                        <p
                          className="p_white staffbio"
                          dangerouslySetInnerHTML={{ __html: item.bio }}
                        />
                      </div>
                    ))}
                  </Col>
                  <Col xs={1} md={0}></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      }
    </Wrapper>
  );
};

export default TheTeam;
