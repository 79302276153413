import React, { Fragment } from "react";
import bgContact from "../../images/contactbg-05.png";
import { Col, Row } from "react-bootstrap";
const NotFound = () => {
  return (
    <Fragment>
      <div
        className="notfound-pagecontainer"
        style={{
          backgroundImage: `url(${bgContact})`,
          backgroundSize: "100%",
          backgroundRepeat: "none",
          backgroundAttachment: "fixed",
        }}
        id="NotFound"
      >
        {
          <>
            <div className="top-buffer"></div>
            <div className="container-fluid">
              <Row>
                <Col xs={1} md={1}></Col>
                <Col xs={6} md={4}>
                  <h1 className="h1_white" expand="lg">
                    404
                  </h1>
                </Col>
              </Row>
              <Row className="rowone-top-buffer">
                <Col xs={1} md={1}></Col>
                <Col className="p_white_contact">Page Not Found</Col>
              </Row>
            </div>
          </>
        }
      </div>
    </Fragment>
  );
};

export default NotFound;
