import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-slideshow-image/dist/styles.css";
import Main from "./Main";
import HomeCuisine from "../Cuisine/mobile/HomeCuisine";
import Concept from "../Concept/Concept";
import Cuisine from "../Cuisine/Cuisine";
import TheTeam from "../TheTeam/TheTeam";
import Seo from "../Global/Seo";
import OpeningTime from "../Contact/OpeningTime";
import SubscriberForm from "../Contact/SubscriberForm";
import ContactFooter from "../Contact/ContactFooter";
import Error from "../Global/Error";
import { env, endpoints } from "../../config/Config";
import fetchData from "../../hooks/fetch-data";
import { isMobile } from "react-device-detect";
import {
  transformBannerData,
  transformConceptData,
  transformCuisineData,
  transformCuisineMenuData,
  transformTeamData,
} from "../../Helper/Helper";

const Home = (props) => {
  const [homeData, setHomeData] = useState([]);
  const [homeImgData, setHomeImgData] = useState([]);
  const [conceptData, setConceptData] = useState([]);
  const [cuisineData, setCuisineData] = useState([]);
  const [menuData, setMenuData] = useState([]);
  const [teamData, setTeamData] = useState([]);
  const [teamImg, setTeamImg] = useState([]);
  const [bgImg, setBgImg] = useState(null);
  const { isLoading, error, sendRequest: fetchDatas } = fetchData();

  useEffect(() => {
    // home page scroll to top.
    window.scrollTo(0, 0);

    // transform data.
    const transformData = (dataObj) => {
      const loadedHomeData = transformBannerData(dataObj);
      const loadedConceptData = transformConceptData(dataObj);
      const loadedCuisineData = transformCuisineData(dataObj);
      const loadedMenuData = transformCuisineMenuData(dataObj);

      // set home page data.
      setHomeData(loadedHomeData["bannerdata"]);
      setHomeImgData(loadedHomeData["bannerimgs"]);
      setConceptData(loadedConceptData);
      setCuisineData(loadedCuisineData);
      setMenuData(loadedMenuData);

      // set team data.
      setTeamData(transformTeamData(dataObj)["teamdata"]);

      // set team page background image.
      setBgImg(transformTeamData(dataObj)["bgimg"]);

      // set team staff imagees.
      setTeamImg(transformTeamData(dataObj)["teamimgs"]);

      // scroll to concept block.
      if (!isLoading && props.currentMenuItem) {
        const timer = setTimeout(() => {
          const body = document.getElementById(props.currentMenuItem);
          if (body) {
            body.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        }, 500);
        return () => clearTimeout(timer);
      }
    };

    // set home page uuid based on current locale.
    const uUid = endpoints[env].UUID;
    let apiPrefix = "/api/";
    if (props.currentLocale === "ar") {
      apiPrefix = "/ar/api/";
    }

    const fetchURL =
      endpoints[env].url +
      apiPrefix +
      endpoints[env].home +
      "/" +
      uUid +
      "?jsonapi_include=1&include=banner,banner.bannerimg,banner.field_banner_video,concept,concept.field_concept_image,conceptbgimg,cuisinebgimg,staffbgimg,cuisine,cuisine.cusineimg,cuisine.cuisinemenu,cuisine.cuisinemenu.field_menus,cuisine.cuisinemenu.field_menu_image,cuisine.cuisinemenu.field_menus.field_menu_image,staff,staff.staffimgs,cuisine.field_cuisine_meals,cuisine.field_cuisine_meals.field_meals_image";
    fetchDatas({ url: fetchURL }, transformData);
  }, [fetchDatas]);

  return (
    <>
      {error && (
        <Error
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
        />
      )}
      <Seo page="home" />
      {!isLoading && (
        <>
          <Main
            textDirection={props.textDirection}
            currentLocale={props.currentLocale}
            data={homeData}
            dataImg={homeImgData}
          />
          <Concept
            textDirection={props.textDirection}
            currentLocale={props.currentLocale}
            data={conceptData}
          />
          {isMobile && (
            <HomeCuisine
              currentLocale={props.currentLocale}
              textDirection={props.textDirection}
              menudata={menuData}
              data={cuisineData}
              onMobileMenuPage={props.onMobileMenuPage}
            />
          )}
          {!isMobile && (
            <Cuisine
              textDirection={props.textDirection}
              currentLocale={props.currentLocale}
              data={cuisineData}
              menudata={menuData}
            />
          )}
          <TheTeam
            textDirection={props.textDirection}
            currentLocale={props.currentLocale}
            data={teamData}
            imgData={teamImg}
            bgImg={bgImg}
          />
          <OpeningTime
            currentLocale={props.currentLocale}
            textDirection={props.textDirection}
          />
          <SubscriberForm
            currentLocale={props.currentLocale}
            textDirection={props.textDirection}
          />
          <ContactFooter
            currentLocale={props.currentLocale}
            textDirection={props.textDirection}
          />
        </>
      )}
    </>
  );
};

export default Home;