import React from "react";
import { Row, Col } from "react-bootstrap";
import { FormattedMessage } from "react-intl";

const MenuList = (props) => {  
  const items = props.data;
  const cSindex = props.mealid;
  const hasSubcategory = props.hasSubcategory;
  return (
    <>
      <Row
        className={`col-xs-12 col-md-8 menulist menulist-${props.currentLocale}`}
        dir={props.textDirection}
      >
        <div>
          <h4>{props.currentMealsTitle}</h4>
        </div>
        {items &&
          items.map((item, key) => (
            <div
              className={`${
                item.id !== cSindex && hasSubcategory == 'yes' ? "hideinitially" : "row"
              }`}
              key={key}
            >
              {item.data.map((itm, kay) => (
                <div className="col-12" key={kay}>
                  <div className="menulistdiv">
                    <Row>
                      <Col xs={12} md={9}>
                        <p className="parap">{itm.itemtitle}</p>
                      </Col>
                      <Col xs={12} md={3}>
                        <p className="parap">QAR {itm.itemprice}</p>
                      </Col>
                    </Row>
                  </div>
                </div>
              ))}
            </div>
          ))}
      </Row>
      <Row
        className={`menulistbutton-${props.currentLocale}`}
        dir={props.textDirection}
      >
        <Col xs={12} md={12}>
          <button
            className=" button_pink rounded-lg"
            variant="secondary"
            onClick={() => props.navigateToMenu(null)}
          >
            <FormattedMessage id="discovercuisine" />
          </button>
        </Col>
      </Row>
    </>
  );
};
export default MenuList;