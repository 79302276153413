import React from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from "react-bootstrap/Carousel";
import Wrapper from "../Global/Wrapper";
import { directionButtons } from "../../Helper/Helper";
import { isMobile } from "react-device-detect";

const Concept = (props) => {
  const conceptData = props.data;
  const bgPosition = props.currentLocale == "en" ? "left" : "right";
  const desktopClass = `pagecontainer concept-${props.currentLocale}`;
  const mobileClass = `pagecontainer concept-${props.currentLocale} pagecontainer-mobile`;

  return (
    <Wrapper
      clsname={`${isMobile ? mobileClass : desktopClass}`}
      bgimage={conceptData["bgImg"]}
      bgsize="cover"
      id="concept"
      bgPosition={bgPosition}
      textDirection={props.textDirection}
    >
      {
        <>
          <div className="top-buffer"></div>
          <div className="container-fluid">
            <Row>
              <Col xs={12} md={1}></Col>
              <Col xs={12} md={4}>
                <Row>
                  <Col xs={1} md={0}></Col>
                  <Col xs={10} md={12}>
                    {conceptData["blockimg"] && (
                      <Carousel
                        indicators={false}
                        autoPlay={true}
                        nextIcon={directionButtons("Next")}
                        prevIcon={directionButtons("Previous")}
                      >
                        {conceptData["blockimg"]?.map((img, key) => (
                          <Carousel.Item key={key}>
                            <img
                              className="img_cpt"
                              src={img.image}
                              alt="cafe_999"
                            />
                          </Carousel.Item>
                        ))}
                      </Carousel>
                    )}
                  </Col>
                  <Col xs={1} md={0}></Col>
                </Row>
              </Col>
              <Col xs={12} md={6}>
                <Row>
                  <Col xs={1} md={0}></Col>
                  <Col xs={10} md={12}>
                    <h1 className="h1_concept">
                      {conceptData["title"]} <br />
                      {conceptData["subtitle"]}
                    </h1>
                    {conceptData.description && (
                      <p
                        className="p_concept"
                        dangerouslySetInnerHTML={{
                          __html: conceptData.description,
                        }}
                      />
                    )}
                  </Col>
                  <Col xs={1} md={0}></Col>
                </Row>
              </Col>
            </Row>
          </div>
        </>
      }
    </Wrapper>
  );
};

export default Concept;
