import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    // menu
    home: "Home",
    concept: "Concept",
    cuisine: "Cuisine",
    news: "News",
    team: "The Team",
    contact_us: "Contact us",
    discovermenu: "DISCOVER OUR",
    themenutitle: "THE MENU",
    discovercuisine: "DISCOVER OUR CUISINES",
    loading: "Loading...",
    morebutton: "More",
    contacttitle: "contact us",
    contactdesc:
      "We’d love to receive your feedback or questions. You can always contact us via email or give us a call.",
    telephonelabel: "Telephone:",
    whatsapplabel: "WhatsApp:",
    emaillabel: "Email:",
    telephonevalue: "+974 4452 5650",
    whatsappvalue: "+974 5588 4166",
    emalvalue: "cafe999restaurant@qm.org.qa",
    openingtime: "Opening Times",
    sunday: "Sunday",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    generalday: "Saturdays to Thursdays",
    saturday: "Saturday",
    generaldayopentime: "9AM - 9PM",
    fridayopentime: "1PM - 9PM",
    signuptext: "Subscribe to our newsletter",
    emailadresslable: "Email Address",
    submitlabel: "Subscribe",
    copyrighttext: "© 2023 Cafe#999",
    errorfetchmsg: "Error: Not able to fetch data from source",
    nodatafound: "No data found",
    notificationmsg: "Vote for us at Fact Dining Awards 2024!",
  },
  [LOCALES.ARABIC]: {
    home: "الرئيسية",
    concept: "مفهوم",
    cuisine: "أطباق",
    news: "أخبار",
    team: "الفريق",
    contact_us: "اتصل بنا",
    discovermenu: "اكتشف",
    themenutitle: "قائمة الطعام",
    discovercuisine: "اكتشف مطبخنا",
    loading: "...جار التحميل",
    morebutton: "More",
    morebutton: "إضافي",
    contacttitle: "اتصلوا بنا",
    contactdesc:
      "يسعدنا تلقي آرائكم أو أسئلتكم. يمكنكم دائمًا التواصل معنا عبر البريد الإلكتروني أو بالاتصال بنا.",
    telephonelabel: "هاتف:",
    whatsapplabel: "واتس اب:",
    emaillabel: "بريد إلكتروني:",
    telephonevalue: "+974 4452 5650",
    whatsappvalue: "+974 5588 4166",
    emalvalue: "cafe999restaurant@qm.org.qa",
    openingtime: "مواعيد العمل",
    sunday: "الأحد",
    monday: "الأثنين",
    tuesday: "الثلاثاء",
    wednesday: "الأربعاء",
    thursday: "الخميس",
    friday: "الجمعه",
    generalday: "من السبت الى الخميس",
    saturday: "السبت",
    generaldayopentime: "9AM - 9PM",
    fridayopentime: "1PM - 9PM",
    signuptext: "اشتركوا في نشرتنا الإخبارية",
    emailadresslable: "بريد إلكتروني",
    submitlabel: "يشترك",
    copyrighttext: "© 2023 Cafe#999",
    errorfetchmsg: "خطأ: غير قادر على جلب البيانات من المصدر",
    nodatafound: "لاتوجد بيانات",
    notificationmsg: "صوّتوا لنا لجوائز مجلة فاكت لعام 2024",
  },
};
