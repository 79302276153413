import { FormattedMessage } from "react-intl";

const Loading = (props) => {
  const displayText = props.displayText||false;
  return (
      <div className="loadingcontainer">
        <div className="loadingtext">
          {!displayText && (
            <div className="spinner-border text-secondary" role="status">
              <span className="sr-only"></span>
            </div>
          )}
          {displayText && <FormattedMessage id="loading" />}
        </div>
        <div className="top-buffer"></div>
      </div>
  );
};

export default Loading;
