import { env, endpoints } from "../config/Config";
import MenuPlaceholder from "../images/catering-item-placeholder.png";
import MenuPlaceholderThumb from "../images/catering-item-placeholder-thumb.png";
import NewsBgImg from "../images/newsbackground.png";

export const directionButtons = (direction) => {
  return (
    <span
      aria-hidden="true"
      className={
        direction === "Next"
          ? "crousel-control-button"
          : "crousel-control-button"
      }
    >
      {direction === "Next" ? ">" : "<"}
    </span>
  );
};

// transform banner data.
export const transformBannerData = (dataObj) => {
  const totalBannerData = [];
  const bannerData = [];
  const bannerImg = [];

  for (const bannerKey in dataObj.data.banner) {

    // transform data.
    bannerData.push({
      id: dataObj.data.banner[bannerKey]?.id,
      type: dataObj.data.banner[bannerKey]?.field_banner_type,
      video:
        endpoints[env].img_url +
        dataObj.data.banner[bannerKey]?.field_banner_video?.uri?.url,
      title: dataObj.data.banner[bannerKey]?.title,
      summary: dataObj.data.banner[bannerKey]?.body?.summary,
      value: dataObj.data.banner[bannerKey]?.body?.value,
    });
    
    // insert image.
    let finale_img =
      endpoints[env].img_url +
      dataObj.data.banner[bannerKey]?.bannerimg?.uri?.url;
    bannerImg.push(finale_img);
  }

  totalBannerData["bannerdata"] = bannerData;
  totalBannerData["bannerimgs"] = bannerImg;
  return totalBannerData;
}

// transform concept data.
export const transformConceptData = (dataObj) => {
  const loadedConceptData = [];
  const loadedConceptImgs = [];

  // load concept data.
  loadedConceptData["bgImg"] =
    endpoints[env].img_url + dataObj.data.conceptbgimg.uri.url;
  loadedConceptData["title"] = dataObj.data.concept.field_concept_title;
  loadedConceptData["subtitle"] = dataObj.data.concept.field_concept_subtitle;
  loadedConceptData["description"] =
    dataObj.data.concept.field_concept_description.value;
  
  for (const cptimgkey in dataObj.data.concept.field_concept_image) {
    loadedConceptImgs.push({
      id: cptimgkey,
      image:
        dataObj.data.concept.field_concept_image[cptimgkey]?.image_style_uri
          .concept,
    });
  }
  loadedConceptData["blockimg"] = loadedConceptImgs;
  return loadedConceptData;
};

// transform cuicine data.
export const transformCuisineData = (dataObj) => {
  const loadedCuisineData = [];

  loadedCuisineData["bgImg"] =
    endpoints[env].img_url + dataObj.data.cuisinebgimg.uri.url;
  loadedCuisineData["title"] = dataObj.data.cuisine.title;
  loadedCuisineData["subtitle"] = dataObj.data.cuisine.field_cuisine_title;
  loadedCuisineData["desc"] = dataObj.data.cuisine.body.value;
  return loadedCuisineData;
};

// transform cuicine menu data.
export const transformCuisineMenuData = (dataObj) => {
  const loadedMenuData = [];

  // load cuisine menu data.
  for (const menuKey in dataObj.data.cuisine.field_cuisine_meals) {
    loadedMenuData.push({
      id: dataObj.data.cuisine.field_cuisine_meals[menuKey].id,
      title: dataObj.data.cuisine.field_cuisine_meals[menuKey].title,
      thumbnailTitle: dataObj.data.cuisine.field_cuisine_meals[menuKey].title,
      thumbnailLabel: dataObj.data.cuisine.field_cuisine_meals[menuKey].title,
      description: dataObj.data.cuisine.field_cuisine_meals[menuKey].title,
      thumbnail:
        dataObj.data.cuisine.field_cuisine_meals[menuKey].field_meals_image?.image_style_uri.medium,
      original:
        dataObj.data.cuisine.field_cuisine_meals[menuKey].field_meals_image?.image_style_uri.cuisine,
    });
  }
  return loadedMenuData;
};

// transform team data.
export const transformTeamData = (dataObj) => {
  const loadedTeamData = [];
  const loadedTeamImg = [];
  const finalTeamData = [];

  //load team image.
  for (const datakey in dataObj.data.staff) {
    loadedTeamData.push({
      id: datakey,
      name: dataObj.data.staff[datakey].title,
      title: dataObj.data.staff[datakey].subtitle,
      bio: dataObj.data.staff[datakey].body.value,
    });
  }

  //load team image.
  for (const imgKey in dataObj.data.staff) {
    loadedTeamImg.push({
      id: imgKey,
      image: dataObj.data.staff[imgKey].staffimgs.image_style_uri.staff,
    });
  }

  finalTeamData["teamdata"] = loadedTeamData;
  finalTeamData["teamimgs"] = loadedTeamImg;
  finalTeamData["bgimg"] =
    endpoints[env].img_url + dataObj.data.staffbgimg.uri.url;
  return finalTeamData;
};

// transoform meals menu data.
export const transformMealsMenu = (dataObj) => {
  const loadedMealsMenu = [];

  //load team image.
  if (dataObj.data.field_has_subcategory == "yes") {
    for (const mealsKey in dataObj.data.field_meals_menu) {
      loadedMealsMenu.push({
        id: dataObj.data.field_meals_menu[mealsKey].id,
        title: dataObj.data.field_meals_menu[mealsKey].title,
        thumbnailTitle: dataObj.data.field_meals_menu[mealsKey].title,
        thumbnailLabel: dataObj.data.field_meals_menu[mealsKey].title,
        subtitle: dataObj.data.field_meals_menu[mealsKey].title,
        description: dataObj.data.field_meals_menu[mealsKey].title,
        thumbnail:
          dataObj.data.field_meals_menu[mealsKey].field_menu_image
            .image_style_uri.medium,
        original:
          dataObj.data.field_meals_menu[mealsKey].field_menu_image
            .image_style_uri.cuisine,
      });
    }
  } else {
    // load menu items
    for (const mealsitemkey in dataObj.data.field_meals_menu) {
      for (const itemKey in dataObj.data.field_meals_menu[mealsitemkey]
        .field_menus) {
        let defaultThumbImage = MenuPlaceholderThumb;
        let defaultLargeImage = MenuPlaceholder;
        if (
          dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
            .field_menu_image.image_style_uri
        ) {
          defaultThumbImage =
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_menu_image.image_style_uri.medium;
          defaultLargeImage =
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_menu_image.image_style_uri.cuisine;
        }
        loadedMealsMenu.push({
          id: dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
            .id,
          title:
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_title,
          thumbnailTitle:
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_title,
          thumbnailLabel:
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_title,
          subtitle:
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_title,
          description:
            dataObj.data.field_meals_menu[mealsitemkey].field_menus[itemKey]
              .field_title,
          thumbnail: defaultThumbImage,
          original: defaultLargeImage,
        });
      }  
    }
  }
  return loadedMealsMenu;
}

// transoform meals menu data.
export const transformMealsMenuItems = (dataObj) => {
  const loadedMealsMenuItems = [];

  //load team image.
  for (const mealsMenuKey in dataObj.data.field_meals_menu) {
    var dtObj = {};
    dtObj["id"] = dataObj.data.field_meals_menu[mealsMenuKey].id;
    const datatopush = [];
    let menuitemimage = MenuPlaceholder;
    for (const itemKey in dataObj.data.field_meals_menu[mealsMenuKey].field_menus) {

      if ( dataObj.data.field_meals_menu[mealsMenuKey].field_menus[itemKey]
          .field_menu_image.image_style_uri ) {
        menuitemimage =
          dataObj.data.field_meals_menu[mealsMenuKey].field_menus[itemKey]
            .field_menu_image.image_style_uri.large;
      }
        datatopush.push({
          id: dataObj.data.field_meals_menu[mealsMenuKey].field_menus[itemKey]
            .id,
          itemtitle:
            dataObj.data.field_meals_menu[mealsMenuKey].field_menus[itemKey]
              .field_title,
          itemprice:
            dataObj.data.field_meals_menu[mealsMenuKey].field_menus[itemKey]
              .field_price,
          itemimage: menuitemimage,
        });
    }
    dtObj["data"] = datatopush;
    loadedMealsMenuItems.push(dtObj);
  }
  return loadedMealsMenuItems;
}

// transform news data
export const transformNews = (dataObj) => {
  const newsItems = [];
  if (dataObj.data.news.length > 0) {
    for (const newsKey in dataObj.data.news) {
      newsItems.push({
        id: dataObj.data.news[newsKey].id,
        title: dataObj.data.news[newsKey].title,
        summary: dataObj.data.news[newsKey].body.summary,
        path: dataObj.data.news[newsKey].path.alias,
        lang: dataObj.data.news[newsKey].path.langcode,
        image: dataObj.data.news[newsKey].field_image.image_style_uri.large,
      });
    }
  }
  return newsItems;
};

// transform news data
export const transformNewsBg = (dataObj) => {
  let newsBgImage = NewsBgImg;
  if (dataObj.data.newsbgimage.uri.url) {
    newsBgImage = endpoints[env].img_url + dataObj.data.newsbgimage.uri.url;
  }
  return newsBgImage;
};

// transform single news data
export const transformSingleNews = (dataObj) => {
  const newsItem = [];
  const tags = []
  for (const tagkey in dataObj.data.field_tags) {
    tags.push(dataObj.data.field_tags[tagkey].name);
  }

  newsItem.push({
    id: dataObj.data.id,
    title: dataObj.data.title,
    body: dataObj.data.body.value,
    summary: dataObj.data.summary,
    image: dataObj.data.field_image.image_style_uri.news,
    tag: tags,
  });
  return newsItem;
};

// transform single news data
export const checkMenuHasSubCat = (dataObj) => {
  return dataObj.data.field_has_subcategory;
}

// transform opening time.
export const transformopeningtime = (data) => {
  const returns = [];
  for (const resKey in data) {
    returns.push({ key: data[resKey].key, value: data[resKey].value});
  }
  return returns;
}

