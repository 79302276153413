import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "react-image-gallery/styles/css/image-gallery.css";
import Loading from "../../Global/Loading";
import Error from "../../Global/Error";
import { env, endpoints } from "../../../config/Config";
import fetchData from "../../../hooks/fetch-data";
import Menulist from "../MenuList";
import {
  transformMealsMenu,
  transformMealsMenuItems,
  checkMenuHasSubCat,
} from "../../../Helper/Helper";

const MobileMenu = (props) => {
  const [menuMeals, setMenuMeals] = useState([]);
  const [menuItems, setMenuItems] = useState([]);
  const [currentMealsId, setCurrentMealsID] = useState(0);
  const [hasSubcategory, setSubcategory] = useState("yes");
  const [currentMealsTitle, setCurrentMealsTitle] = useState(null);  
  const { isLoading, error, sendRequest: fetchDatas } = fetchData();
  const goToHome = () => {
    props.returnFromMenu();
  };
  
  useEffect(() => {   
  }, []);

  useEffect(() => {
    // transform data.
    const transformData = (dataObj) => {
      setMenuMeals(transformMealsMenu(dataObj));
      setMenuItems(transformMealsMenuItems(dataObj));
      setSubcategory(checkMenuHasSubCat(dataObj));
      if (checkMenuHasSubCat(dataObj) === "no") {
        setCurrentMealsTitle(dataObj.data.title);
      } else {
        setCurrentMealsID(transformMealsMenu(dataObj)[0].id);
        setCurrentMealsTitle(transformMealsMenu(dataObj)[0].title);
      }

      // scroll to concept block.
      if (!isLoading) {
        const timer = setTimeout(() => {
          const body = document.getElementById("cuisine");
          if (body) {
            body.scrollIntoView({
              block: "start",
              behavior: "smooth",
            });
          }
        }, 500);
        return () => clearTimeout(timer);
      }
    };

    // set home page uuid based on current locale.
    const uUid = endpoints[env].UUID;
    let apiPrefix = "/api/";
    if (props.currentLocale === "ar") {
      apiPrefix = "/ar/api/";
    }

    const fetchURL =
      endpoints[env].url +
      apiPrefix +
      "node/meals/" +
      props.menuid +
      "/?jsonapi_include=1&include=field_meals_menu,field_meals_menu.field_menu_image,field_meals_menu.field_menus,field_meals_menu.field_menus.field_menu_image";
    fetchDatas({ url: fetchURL }, transformData);
  }, [fetchDatas]);

  return (
    <>
      {error && (
        <Error
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
        />
      )}
      {isLoading && (
        <Loading
          textDirection={props.textDirection}
          currentLocale={props.currentLocale}
          displayText={false}
        />
      )}
      {!isLoading && (
        <>
          <Row
            className={`mobileMenu-${props.currentLocale}`}
            dir={props.textDirection}
          >
            <Col xs={1} md={1}></Col>
            <Col xs={10} md={10}>
              <span className="closemenu" onClick={() => goToHome()}>
                X
              </span>
            </Col>
            <Col xs={1} md={1}></Col>
          </Row>
          <Row>
            <Col xs={1} md={1}></Col>
            <Col xs={10} md={10} className="mobileMenulist">
              <div>
                <h4>{currentMealsTitle}</h4>
              </div>
              {menuItems &&
                menuItems.map((item, key) => (
                  <Row key={key}>
                    <Col xs={12} md={12}>
                      {item.data.map((subitem, subkey) => (
                        <Row key={subkey}>
                          <Col xs={6} md={6}>
                            <p className="parap">{subitem.itemtitle}</p>
                          </Col>
                          <Col xs={6} md={6}>
                            <p className={`mobileMenuParap-${props.currentLocale}`}>
                              QAR {subitem.itemprice}
                            </p>
                          </Col>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                ))}
            </Col>
            <Col xs={1} md={1}></Col>
          </Row>
        </>
      )}
    </>
  );
};
export default MobileMenu;